$color-white: #ffffff;
$color-black: #000000;

$color-blue: #5e8cb3;
$color-light-grey: #e3e6e8;
$color-green: #01cc34;

$color-menu: #172531;
$color-menu-background: #456f93;

$color-beige: #E8E6E1;
