@import "../../styles/mixins";
@import "../../styles/variables";

$baseName: "contatti";

.site .#{$baseName} {
  position: relative;
  height: 100%;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  @include media-breakpoint-up(md) {
    padding-top: 24px;
  }

  &__title,
  &__dove {
    text-align: center;
    margin-bottom: 24px;
  }

  &__dove {
    @include media-breakpoint-up(md) {
      margin-top: 24px;
    }
  }

  &__vetrina {
    height: 100%;
    width: 100%;
    text-align: center;

    &__foto {
      width: 100%;
      height: 100%;
      border-radius: 0 24px;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);

      @include media-breakpoint-up(lg) {
        height: 500px;
        width: 75%;
      }
    }
  }

  &__details {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 18px 0;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;

      &__phone-icon,
      &__email-icon,
      &__address-icon,
      &__facebook-icon {
        width: 25px;
        height: 25px;
        margin: 5px 10px;

        @include media-breakpoint-up(md) {
          width: 40px;
          height: 40px;
          margin: 10px 10px;
        }
      }

      &__whatsapp-icon {
        width: 30px;
        height: 30px;
        margin: 5px 10px;

        @include media-breakpoint-up(md) {
          width: 45px;
          height: 45px;
          margin: 10px 10px;
        }
      }
    }

    &__text {
      @include font-montserrat-500;
      @include media-breakpoint-up(md) {
        font-size: rem-calc(16);
        &:hover {
          color: $color-green;
        }
      }
      @include media-breakpoint-up(lg) {
        font-size: rem-calc(18);
      }
    }
  }
  &__map {
    height: 400px;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(md) {
      height: 480px;
    }
  }
}
