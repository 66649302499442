@import "../../../styles/mixins";
@import "../../../styles/variables";

$baseName: "footer";

.site .#{$baseName} {
  position: relative;
  width: 100%;
  background: $color-menu;
  background: linear-gradient(
    270deg,
    $color-menu 0%,
    $color-menu-background 86%
  );

  .col-12 {
    border-bottom: 1px solid $color-light-grey;
    padding: 10px 0;
    @include media-breakpoint-up(md) {
      border-right: 1px solid $color-light-grey;
      border-bottom: unset;
      margin: 20px 0;
      &:last-child {
        border-right: 0;
      }
    }
  }

  &__header {
    text-align: center;
    text-transform: uppercase;
    color: $color-white;
    margin: 0 10px;
    padding-bottom: 10px;
  }

  &__ciao {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      margin: 10px 0;
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;

      &__whatsapp-icon,
      &__facebook-icon {
        width: 25px;
        height: 25px;
        margin: 5px 10px;

        @include media-breakpoint-up(md) {
          margin: 10px 10px;
        }
      }
    }

    &__text {
      color: $color-white;
      font-size: rem-calc(15);
    }
  }

  &__payments {
    height: auto;
    width: auto;
    text-align: center;

    &__logo {
      width: 40px;
      height: 25px;
      margin: 5px;

      @include media-breakpoint-up(md) {
        margin: 10px;
        width: 80px;
        height: 50px;
      }

      &__vpay {
        width: 25px;
        height: 25px;
        margin: 5px;

        @include media-breakpoint-up(md) {
          margin: 10px;
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  &__open {
    margin: 0 0 15px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-light-grey;

    @include media-breakpoint-up(md){
      padding-bottom: 0;
      margin: 10px 20px 15px 20px;
    }
    @include media-breakpoint-up(xxl){
      margin: 10px 100px 15px 100px;
    }

    &__container {
      width: 100%;
      display: inline-table;

      &__day {
        text-align: center;
        margin: 10px 0 5px 0;
        color: $color-white;

        @include media-breakpoint-up(lg) {
          float: left;
        }
      }

      &__time {
        text-align: center;
        margin: 10px 0 5px 0;
        color: $color-white;

        @include media-breakpoint-up(lg) {
          float: right;
        }
      }

      &__piva {
        text-align: center;
        margin: 50px 0 5px 0;

        @include media-breakpoint-up(lg) {
          float: left;
        }
      }

      &__number {
        text-align: center;
        margin: 50px 0 5px 0;

        @include media-breakpoint-up(lg) {
          float: right;
        }
      }
    }
  }

  &__links {
    &__container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      &__link {
        color: $color-white;
        padding: 5px 0;
        margin-bottom: 5px;
        text-decoration: underline;

        &:hover {
          color: $color-green;
        }
      }
    }
  }

  &__fisco {
    margin-top: 10px;
    color: $color-white;

    @include font-montserrat-400;
    text-align: center;
    font-size: rem-calc(14);
  }

  &__copyright {
    @include font-montserrat-400;
    font-size: rem-calc(12);
    text-align: center;
    color: $color-white;

    &__name {
      @include font-montserrat-400;
      font-size: rem-calc(12);
      text-align: center;
      color: #9bb7d0;
      text-decoration: underline;

      &:hover {
        color: $color-green;
        text-decoration: underline;
      }
    }
  }
}
