@import "../../styles/mixins";
@import "../../styles/variables";

$baseName: "home";
.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;

  .carousel-indicators li {
    background-color: $color-black;
  }

  .carousel-inner {
    .#{$baseName} {
      &__carousel {
        &__item {
          &__image {
            max-width: 100%;
            height: 30vh;
            vertical-align: middle;
            border: 0;
            -ms-interpolation-mode: bicubic;

            @include media-breakpoint-up(md) {
              height: calc(50vh - 60px);
            }
            @include media-breakpoint-up(xl) {
              height: calc(100vh - 70px);
            }
          }

          &__caption {
            padding-bottom: 12px;

            &__title {
              color: $color-black;
              font-size: rem-calc(16);

              @include media-breakpoint-up(md) {
                font-size: rem-calc(22);
              }
            }

            &__text {
              display: none;
              color: $color-black;

              @include media-breakpoint-up(md) {
                display: block;
                font-size: rem-calc(20);
              }
            }
          }
        }
      }
    }
  }

  &__card {
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
    margin-top: 18px;

    &__image {
      height: 17vh;
    }

    &__body {
      &__text {
        @include media-breakpoint-up(md) {
          height: 200px;
          font-size: 15px;
        }
        @include media-breakpoint-up(lg) {
          height: 150px;
        }
        @include media-breakpoint-up(xl) {
          height: 100px;
        }
      }

    }
  }
}
