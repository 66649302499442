@import "../../../styles/mixins";
@import "../../../styles/variables";

$baseName: "emoglobina";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  margin: 18px 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    margin: 30px 0 18px 0;
  }


  &__container {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 18px 0;

    &__image {
      width: 100%;
      border-radius: 5px;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);

      @include media-breakpoint-up(lg) {
        width: 70%;
      }
    }

    &__text {
      margin-top: 18px;
    }
  }
}