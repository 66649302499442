@import "../../../styles/mixins";
@import "../../../styles/variables";

$baseName: "pampers";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  &__container {
    text-align: center;

    &__logo {
      width: auto;
      height: 70px;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        height: 100px;
      }
    }
  }

  &__title {
    text-align: center;
    margin: 18px 0;

    @include media-breakpoint-up(md) {
      margin: 30px 0 18px 0;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 18px;

    &__image {
      width: 100%;
      border-radius: 5px;
      margin-bottom: 18px;

      @include media-breakpoint-up(lg) {
        width: 80%;
      }
    }

    &__text {
      margin-top: 18px;
    }
  }
}
