@import "../../../styles/mixins";
@import "../../../styles/variables";

$baseName: "difese";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  &__title {
    text-align: center;
    margin: 18px 0;

    @include media-breakpoint-up(md) {
      margin: 30px 0 18px 0;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 18px;

    &__image {
      width: 70%;
      border-radius: 5px;
      margin-bottom: 18px;

      @include media-breakpoint-up(lg) {
        width: 40%;
      }
      @include media-breakpoint-up(xxl) {
        width: 30%;
      }
    }

    &__text {
      margin-top: 18px;
    }
  }
}

