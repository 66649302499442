@import "../../styles/mixins";
@import "../../styles/variables";

$baseName: "parafarmaco";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  p,
  site-p {
    margin-bottom: 0;
    font-size: rem-calc(16);

    @include media-breakpoint-up(md) {
      font-size: rem-calc(14);
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 18px;
  }

  &__text {
    text-align: center;

    &__last {
      @include font-montserrat-600;
    }
  }

  &__container {
    &__image {
      position: relative;
      margin: 12px 0;
      padding-top: 125%;
      height: 0;
      background-position: center;
      background-size: cover;
      border-radius: 5px;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);


      &:hover {
        .#{$baseName}__container__image__overlay {
          cursor: pointer;
          opacity: 1;
          pointer-events: all;
        }
      }

      &__overlay-responsive {
        .#{$baseName}__container__image__overlay {
          cursor: pointer;
          opacity: 1;
          pointer-events: all;
        }
      }

      &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        background: $color-green;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-out;
        padding: 24px 20px;
        border-radius: 5px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);


        &__description {
          font-size: 1rem;
          position: absolute;
          top: 50%;
          right: 50%;
          -ms-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
          text-align: center;
          @include font-montserrat-600;
        }
      }
    }
  }
}
