@import "../../../styles/mixins";
@import "../../../styles/variables";

$baseName: "vitamine";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  &__logo-container {
    text-align: center;

    &__logo {
      width: auto;
      height: 70px;
      margin-bottom: 18px;

      @include media-breakpoint-up(md) {
        height: 100px;
      }
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 18px;

    &__image {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 40%;
      }
    }

    &__sub-title {
      margin: 18px 0;
    }

    &__text {
      margin-top: 18px;
    }
  }
}
