@import "../../../styles/mixins";
@import "../../../styles/variables";

$baseName: "makeup";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  &__title {
    margin: 18px 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin: 30px 0 18px 0;
    }
  }

  &__container {
    width: 100%;
    text-align: center;
    margin: 18px 0;

    &__text {
      @include font-montserrat-600;
    }

    &__image-container {
      display: flex;
      justify-content: center;

      @include media-breakpoint-up(xs) {
        flex-direction: column;
        align-items: center;
      }
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      &__image {
        height: 20vh;
        border-radius: 5px;
        @include media-breakpoint-up(xs) {
          width: 100%;
        }
        @include media-breakpoint-up(md) {
          width: 30vw;
        }
      }
    }
  }
}


