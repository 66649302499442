@import "../../styles/mixins";
@import "../../styles/variables";

$baseName: "dermocosmesi";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  &__header {
    text-align: center;
    margin-bottom: 18px;
  }

  &__text {
    text-align: center;

    &__last {
      @include font-montserrat-600;
    }
  }

  &__container {
    display: flex;
    justify-content: center;

    &__image {
      position: relative;
      width: 100%;
      border-radius: 5px;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);


      &__overlay-right {
        position: absolute;
        background-color: $color-light-grey;
        opacity: 0;
        top: 0;
        bottom: 0;
        left: 50%;
        right: 0;
        margin-right: 18px;
        border-radius: 0 5px 5px 0;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);

        &:hover {
          cursor: pointer;
          opacity: 1;
          pointer-events: all;
        }

        &__description {
          position: absolute;
          font-size: 1rem;
          top: 50%;
          right: 50%;
          -ms-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
          text-align: center;
          @include font-montserrat-600;
        }
      }

      &__overlay-left__responsive {
        .#{$baseName}__container__image__overlay-left {
          cursor: pointer;
          opacity: 1;
          pointer-events: all;
        }
      }

      &__overlay-left {
        position: absolute;
        background-color: $color-green;
        opacity: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 50%;
        margin-left: 18px;
        border-radius: 5px 0 0 5px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);

        &:hover {
          cursor: pointer;
          opacity: 1;
          pointer-events: all;
        }

        &__description {
          position: absolute;
          font-size: 1rem;
          top: 50%;
          right: 50%;
          -ms-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
          text-align: center;
          @include font-montserrat-600;
        }

      }
    }
  }
}
