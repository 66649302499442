@import "../../../styles/mixins";
@import "../../../styles/variables";

$baseName: "fiocchi";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  &__logo-container {
    text-align: center;

    &__logo {
      width: auto;
      height: 100px;
      margin-bottom: 18px;

      @include media-breakpoint-up(md) {
        height: 150px;
      }
    }
  }

  &__title {
    margin: 18px 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin: 30px 0 18px 0;
    }
  }

  &__container {
    width: 100%;
    text-align: center;
    margin: 18px 0;

    &__image {
      width: 100%;
      border-radius: 5px;

      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }

    &__image-talco {
      margin: 18px 0;
      height: 200px;
      width: auto;

      @include media-breakpoint-up(md) {
        height: 300px;
      }
      @include media-breakpoint-up(xl) {
        height: 500px;
      }

    }


    &__block {
      background-color: $color-beige;
      border: 3px solid $color-white;

      &__title {
        padding: 9px 0;
        @include font-montserrat-600;
      }
    }

    &__text {
      margin-top: 18px;
    }
  }
}
