@import "mixins";
@import "variables";

html.site * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}

html.site {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  body.cookieBar-fixed-body {
    position: fixed;
    @include media-breakpoint-up(md) {
      position: unset;
    }
  }
}

html.site body {
  min-height: auto;
  overflow-x: hidden;
  padding-top: 48px;

  @include media-breakpoint-up(md) {
    padding-top: 60px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 70px;
  }

  &.no-scrolling {
    position: fixed;
    width: 100%;
  }

  .page-container {
    position: relative;
  }

  .content-wrap {
    padding-bottom: 18px;

    min-height: calc(100vh - 319px);
  }
}
