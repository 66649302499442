@import "../../styles/mixins";
@import "../../styles/variables";

$baseName: "infanzia";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  p, site-p {
    margin-bottom: 0;
    font-size: rem-calc(16);

    @include media-breakpoint-up(md) {
      font-size: rem-calc(14);
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 18px;
  }

  &__text {
    text-align: center;

    &__last {
      @include font-montserrat-600;
    }
  }

  &__container {

    &__image {
      width: 100%;
      height: 90%;
      padding: 18px 0;
    }
  }
}
