@import "../../styles/mixins";
@import "../../styles/variables";

$baseName: "autoanalisi";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  &__header, &__subheader, &__text {
    text-align: center;
    margin-bottom: 18px;
  }

  &__subheader {
    @include font-montserrat-600;
    color: $color-green;
  }

  &__container {
    .flip-card {
      background-color: transparent;
      width: 100%;
      height: 300px;
      perspective: 1000px;
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .responsive {
      transform: rotateY(180deg);

      .flip-card-back p {
        transform: rotateY(180deg);
      }
    }

    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-front, .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .flip-card-front {
      background-color: #bbb;
      color: black;

      &__image {
        width: 100%;
        height: 300px;
        border-radius: 5px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
      }
    }

    .flip-card-back {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-blue;
      transform: rotateY(180deg);
      border-radius: 5px;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);


      p {
        color: white;
        font-size: 1rem;
        @include font-montserrat-600;
      }
    }
  }
}
