@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

@mixin reset-button-style() {
  background: none;
  border: none;
  padding: 0;
  &:focus {
      outline: none;
  }
}

//Font Family
@mixin font-montserrat {
  font-family: montserrat;
}

//Light
@mixin font-montserrat-300 {
  @include font-montserrat;
  font-weight: 300;
}

//Regular
@mixin font-montserrat-400 {
  @include font-montserrat;
  font-weight: 400;
}

//Medium
@mixin font-montserrat-500 {
  @include font-montserrat;
  font-weight: 500;
}

//Semi Bold
@mixin font-montserrat-600 {
  @include font-montserrat;
  font-weight: 600;
}

//Bold
@mixin font-montserrat-700 {
  @include font-montserrat;
  font-weight: 700;
}

//Extra Bold
@mixin font-montserrat-800 {
  @include font-montserrat;
  font-weight: 800;
}

