@import "./mixins";
@import "./variables";

p,
.p,
.site-p {
  @include font-montserrat-400;
  color: $color-black;
  font-size: rem-calc(16);

  b,
  &.b {
    @include font-montserrat-400;
  }

  i,
  &.i {
    font-style: italic;
  }

  ul {
    list-style-position: inside;
    padding-left: 0;
    margin-left: 21px;
  }
}

ol,
ul,
li {
  @include font-montserrat-400;
}

a,
.a,
.site-a {
  @include font-montserrat-400;

  font-size: rem-calc(14);
  line-height: 1;
  color: $color-black;
  text-decoration: none;
  padding: 0;

  border: none;
  outline: none;
  background: none;

  cursor: pointer;

  &:hover,
  &:focus,
  &.focus {
    text-decoration: none;
    outline: none;
    color: $color-black;
  }

  @include media-breakpoint-up(md) {
    font-size: rem-calc(13);
  }

  @include media-breakpoint-up(lg) {
    font-size: rem-calc(15);
  }

  @include media-breakpoint-up(xxl) {
    font-size: rem-calc(20);
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0;
}

h1,
.h1,
.site-h1 {
  @include font-montserrat-600;
  color: $color-black;
  text-transform: none;
  font-size: rem-calc(22);
  line-height: 1;

  @include media-breakpoint-up(md) {
    font-size: rem-calc(28);
  }
}

h2,
.h2,
.site-h2 {
  @include font-montserrat-500;
  color: $color-black;
  text-transform: none;

  font-size: rem-calc(20);
  line-height: 1;
  @include media-breakpoint-up(md) {
    font-size: rem-calc(26);
  }
}

h3,
.h3,
.site-h3 {
  @include font-montserrat-400;
  color: $color-black;
  text-transform: none;
  font-size: rem-calc(18);
  line-height: 1;
  @include media-breakpoint-up(md) {
    font-size: rem-calc(24);
  }
}

h4,
.h4,
.site-h4 {
  @include font-montserrat-400;
  color: $color-black;
  text-transform: none;
  font-size: rem-calc(16);
  line-height: 1;

  @include media-breakpoint-up(md) {
    font-size: rem-calc(18);
  }
}
