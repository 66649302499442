@import "../../../styles/mixins";
@import "../../../styles/variables";

$baseName: "mellin";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  &__container {
    text-align: center;

    &__logo {
      width: auto;
      height: 70px;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        height: 100px;
      }
    }
  }

  &__introduction {
    &__title, &__text {
      text-align: center;
      margin-top: 18px;
    }
  }

  &__block {
    text-align: center;

    &__title {
      margin: 18px 0;

      @include media-breakpoint-up(md) {
        margin: 30px 0 18px 0;
      }

      &--left {
        text-align: left;
      }

      &--right {
        text-align: right;
      }
    }

    &__image {
      width: 70%;

      @include media-breakpoint-up(md) {
        width: 300px;
      }

      &--left {
        float: none;

        @include media-breakpoint-up(md) {
          float: left;
          margin: 0 10px 0 0;
        }
      }

      &--right {
        float: none;

        @include media-breakpoint-up(md) {
          float: right;
          margin: 0 0 0 10px;
        }
      }
    }

    &__text {
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: unset;
      }
    }
  }
}
