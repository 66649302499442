.site .site-icon {
    width: 100%;
    height: 100%;
    position: relative;
  
    .inline-svg {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      
      svg {
        width: 100%;
        height: 100%;
      }
    }
    
    &--with-hover:hover {
        .site-icon__svg-hovered {
          opacity: 1;
        }
        .site-icon__svg{
          opacity: 0;
        }
    }
  
    &__svg, &__svg-hovered {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.2s linear;
    }
  
    &__svg-hovered {
      opacity: 0;
    }
  }