@import "../../../styles/mixins";
@import "../../../styles/variables";

$baseName: "navigation";
.site .#{$baseName} {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  z-index: 202;
  transition: background-color 0.2s;
  background: $color-menu;
  background: linear-gradient(
    270deg,
    $color-menu 0%,
    $color-menu-background 86%
  );

  @include media-breakpoint-up(md) {
    height: 60px;
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    height: 70px;
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding-left: 18px;
  }

  &__header {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    @include media-breakpoint-up(lg) {
      top: unset;
      transform: unset;
      position: unset;
      margin: 0.5rem;
    }

    &__title {
      margin-left: 76px;
      color: $color-white;
      font-size: rem-calc(22);

      @include media-breakpoint-up(md) {
        margin-left: 85px;
        font-size: rem-calc(30);
      }
      @include media-breakpoint-up(xl) {
        margin-left: 95px;
      }
    }

    &__sub-title {
      margin-left: 76px;
      color: $color-white;
      @include media-breakpoint-up(md) {
        margin-left: 85px;
      }
      @include media-breakpoint-up(xl) {
        margin-left: 95px;
      }
    }

    &__logo {
      position: absolute;
      top: -5px;
      left: 18px;
      height: 48px;
      width: 48px;

      @include media-breakpoint-up(md) {
        height: 60px;
        width: 60px;
      }
      @include media-breakpoint-up(lg) {
        top: 0;
        left: 25px;
        width: 70px;
        height: 70px;
      }
    }
  }

  &__menu {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      height: 100%;
    }

    &__list {
      display: flex;
      margin: 0;
      padding: 0;

      &__item {
        list-style: none;

        &--active {
          .#{$baseName}__menu__list__item__link {
            color: $color-green;
          }
        }

        &:hover {
          background-color: $color-menu-background;
        }

        &:hover {
          ~ .#{$baseName}__menu__list__item__link {
            color: $color-green;
          }
        }

        @include media-breakpoint-up(lg) {
          border-radius: 24px 0;
        }

        &__link {
          position: relative;
          display: block;
          text-decoration: none;
          color: $color-white;
          padding: 28px 18px;
          @include font-montserrat-600;

          &:hover {
            color: $color-green;
            overflow: hidden;
          }

          &:hover ~ .#{$baseName}__menu__sub-list {
            display: block;
          }

          @include media-breakpoint-up(lg) {
            padding: 27px 5px 28px 5px;
          }
          @include media-breakpoint-up(xl) {
            padding: 27px 10px 28px 10px;
          }
          @include media-breakpoint-up(xxl) {
            padding: 25px 20px;
          }
        }
      }
    }

    &__sub-list {
      display: none;
      @include media-breakpoint-up(lg) {
        display: none;
        position: absolute;
        overflow: hidden;
        list-style: none;
        padding: 0;
        border-radius: 0 24px;
        padding-top: 2px;

        &:hover {
          display: block;
        }
      }

      &__item {
        text-align: left;
        background-color: $color-menu-background;

        &--active {
          .#{$baseName}__menu__sub-list__item__link {
            color: $color-green;
          }
        }

        &:hover {
          .#{$baseName}__menu__sub-list__item__link {
            color: $color-black;
          }

          background-color: $color-light-grey;
        }

        &__link {
          color: $color-white;
          text-decoration: none;
          position: relative;
          display: block;
          @include font-montserrat-500;

          @include media-breakpoint-up(lg) {
            padding: 20px 5px;
          }
          @include media-breakpoint-up(xl) {
            padding: 20px 10px;
          }
          @include media-breakpoint-up(xxl) {
            padding: 20px 20px;
          }
        }
      }
    }
  }

  &__toggle-button {
    @include reset-button-style;
    position: fixed;
    top: 0;
    right: 0;
    display: block;
    height: 48px;
    width: 58px;
    transition: background-color 0.2s;
    z-index: 202;

    @include media-breakpoint-up(md) {
      height: 60px;
    }

    @include media-breakpoint-up(lg) {
      display: none;
      height: 64px;
      width: 100px;
    }

    &--hide-background {
      background: none;

      .#{$baseName}__toggle-button {
        &__line {
          &--line-1 {
            transform: translateY(0) rotate(45deg);
            background-color: $color-white;
          }

          &--line-2 {
            opacity: 0;
          }

          &--line-3 {
            transform: translateY(0) rotate(-45deg);
            background-color: $color-white;
          }
        }
      }
    }

    &__line {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      height: 2px;
      border-radius: 2px;
      background-color: $color-white;

      &--line-1,
      &--line-3 {
        width: 30px;
        transition: transform 0.2s ease-in-out;
      }

      &--line-1 {
        transform: translateY(-6px);
      }

      &--line-2 {
        width: 24px;
        margin-right: 14px;
        transition: opacity 0.2s ease-in-out;
      }

      &--line-3 {
        transform: translateY(6px);
      }
    }
  }

  &__overlay {
    opacity: 0;
    transition: opacity 0.2s ease-out;

    overflow: auto;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 201;

    display: inline-block;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
    pointer-events: none;

    background: $color-menu;
    background: linear-gradient(
      270deg,
      $color-menu 0%,
      $color-menu-background 86%
    );

    &--active {
      opacity: 1;
      pointer-events: all;
    }

    &__list {
      margin-top: 30%;
      list-style: none;
      padding: 0;

      &__item {
        text-align: center;
        margin-bottom: 40px;

        &--active {
          .#{$baseName}__overlay__list__item__link {
            &:after {
              transform: scaleX(1);
            }
          }
        }

        &--has-dropdown {
          & > a {
            padding-left: 44px;
          }
        }

        &.active {
          .#{$baseName}__overlay__list__item {
            &__open-close {
              transform: rotate(45deg) translateY(2px);
            }
          }

          .#{$baseName}__overlay__sub-list {
            display: block;
            height: auto;
            opacity: 1;
            transition: all ease 1s;
          }
        }

        &__open-close {
          background: none;
          border: none;
          outline: none;

          margin-left: 16px;

          transition: transform 0.3s ease-out;
          transform: rotate(0) translateY(2px);

          &__icon {
            width: 20px;
            height: 20px;
          }

          &:focus {
            outline: none;
          }
        }

        &__link {
          color: $color-white;
          text-decoration: none;
          text-transform: uppercase;
          position: relative;

          font-size: rem-calc(22);

          &:hover {
            color: $color-white;
            text-decoration: none;
          }

          &:after {
            content: "";

            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;

            display: block;
            height: 3px;
            background-color: $color-green;
            transform-origin: center;
            transform: scaleX(0);
            transition: transform 0.2s ease-out;
          }
        }
      }
    }

    &__sub-list {
      display: none;
      list-style: none;
      padding: 0;
      height: 0;
      opacity: 0;
      transition: all ease 0.2s;

      &--active {
        .#{$baseName}__overlay__sub-list__item__link {
          &:after {
            transform: scaleX(1);
          }
        }
      }

      &__item {
        text-align: center;
        margin-top: 16px;

        &--active {
          .#{$baseName}__overlay__sub-list__item__link {
            &:after {
              transform: scaleX(1);
            }
          }
        }

        &__link {
          color: $color-white;
          text-decoration: none;
          position: relative;

          &:hover {
            color: $color-white;
          }

          font-size: rem-calc(18);

          &:after {
            content: "";

            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;

            display: block;
            height: 2px;
            background-color: $color-white;
            transform-origin: center;
            transform: scaleX(0);
            transition: transform 0.2s ease-out;
          }
        }
      }
    }
  }
}
