@import "../../styles/mixins";
@import "../../styles/variables";

$baseName: "chisiamo";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  &__header {
    text-align: center;
    margin-bottom: 18px;
  }

  &__text {
    text-align: center;
    margin-bottom: 18px;
    line-height: 1.2;
  }

  &__member {
    position: relative;
    margin: 12px 0;
    padding-top: 125%;
    height: 0;
    background-position: center;
    background-size: cover;

    &:hover {
      .#{$baseName}__member__overlay {
        cursor: pointer;
        opacity: 1;
        pointer-events: all;
      }
    }

    &__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: #fff;
      background: $color-blue;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-out;
      padding: 24px 20px;
    }

    &__name {
      font-size: 1rem;
      @include font-montserrat-700;
    }

    &__function {
      @include font-montserrat-400;
      font-style: italic;
      font-size: 0.75rem;
      line-height: 1.67;
    }

    &__description {
      margin-top: 10px;
      @include font-montserrat-400;
      font-size: 0.75rem;
      line-height: 1.67;
    }
  }
}
