@import "../../../styles/mixins";
@import "../../../styles/variables";

$baseName: "euphidra";

.site .#{$baseName} {
  position: relative;
  margin: 0 auto;
  max-width: $max-screen-filling-width;
  padding-top: 18px;

  &__logo-container {
    text-align: center;

    &__logo {
      width: auto;
      height: 70px;
      margin-bottom: 18px;

      @include media-breakpoint-up(md) {
        height: 100px;
      }
    }
  }

  &__title {
    margin: 18px 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin: 30px 0 18px 0;
    }
  }

  &__sub-title {
    @include font-montserrat-500; 
    text-align: center;
    margin-top: 18px;
  }

  &__container {
    text-align: center;
    margin: 18px 0;

    &__image {
      width: 100%;
      border-radius: 5px;

      @include media-breakpoint-up(lg) {
        width: 80%;
      }
    }

    &__text {
      margin-top: 18px;
    }

    &__block {

      &__title {
        margin: 18px 0;
      }

      &__image {
        width: 100%;
        margin-bottom: 18px;
        border-radius: 5px;

        @include media-breakpoint-up(md) {
          width: 60%;
        }

        @include media-breakpoint-up(xxl) {
          width: 40%;
        }
      }
    }
  }
}
